@import "src/styles/variables", "src/styles/breakpoints";

.Header {
  &-ExamTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;

    background: linear-gradient(90deg, #f99117, #fcb630);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      &::after {
        background-position-x: -1.8rem;
      }
    }

    &_open {
      &::after {
        transform: rotate(180deg);
      }
    }
    &_nmt {
      &::after {
        background: none;
      }
    }

    @include media-breakpoint-up(md) {
      background: none;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      &:hover {
        &::after {
          background-position-x: -1.9rem;
        }
      }
    }
  }

  &-ExamTitleFirstPart {
    color: $black;
  }
  &-ExamTitleNameWrapper {
    position: relative;
    background: linear-gradient(90deg, #f99117, #fcb630);
    -webkit-background-clip: text;
    margin-right: 5px;

    
  }
  &-ExamTitleName {
    &:hover {
      color: $danger;
    }

    &::after {
      content: "";
      display: inline-block;
      top: 0.6rem;
      right: 0.5rem;

      width: 1.25rem;
      height: 0.75rem;
      margin: 4px;

      background: url("./assets/arrows.svg") no-repeat;
      background-position-x: -1.8rem;
      background-size: cover;
      opacity: 0.6;

      transition: transform 0.1s linear;

      @include media-breakpoint-up(md) {
        top: 0.8rem;

        background-position-x: 0;
        opacity: 1;
      }
    }

    &:hover {
      &::after {
        background-position-x: -1.8rem;
        @include media-breakpoint-up(md) {
          background-position-x: -1.7rem;
        }
      }
    }

    &_open {
      &::after {
        transform: rotate(180deg);
      }
      &:hover::after {
        background-position-x: -1.8rem;
        @include media-breakpoint-up(md) {
          background-position-x: -1.9rem;
        }
      }
    }
  }
}
