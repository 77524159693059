@import "src/styles/breakpoints", "src/styles/variables";

.PasswordToggler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  box-sizing: content-box;
  width: 1.25rem;
  padding: 0 $input-padding-side;

  &:active {
    opacity: 0.6;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 0.6;
    }
  }

  &-Icon {
    width: 1.25rem;
    height: 1.25rem;

    background: url("./PasswordToggler.assets/PasswordTogglerIcon_action_show.svg")
      center no-repeat;

    &_action_close {
      background: url("./PasswordToggler.assets/PasswordTogglerIcon_action_hide.svg")
        center no-repeat;
    }
  }
}
