.PinnedTasks {
  &-Icon {
    width: 24px;
    height: 24px;
  }

  &-Caption {
    margin-left: 0.75rem;
    padding-bottom: 0.2rem;
  }
}
