@import "src/styles/breakpoints";

.ContentTopAd-Yandex {
  height: 300px;

  @include media-breakpoint-up(xs) {
    height: 260px;
  }

  @include media-breakpoint-up(sm) {
    height: 150px;
  }

  @include media-breakpoint-up(md) {
    height: 90px;
    overflow: hidden;
  }
}
