@import "src/styles/variables", "src/styles/breakpoints";

.DarkSwitcher {
    &-switcher {
        cursor: pointer;
        height: 30px;
        position: initial;
        margin-right: 7px;
        
        @include media-breakpoint-up(md) {
            margin-right: 0;
            position: absolute;
            right: 45px;
            top: 10px;
            padding: 0;
            border-radius: 5px;
            
        }
    }
    &-switcher img {
        width: 30px;
        height:30px;
    }

}