@import "src/styles/variables", "src/styles/breakpoints";

$breakpoint-up-two-columns: "screen and"
  (
    min-width: 830px
  );

.ConstructorForm {
  @include media-breakpoint-up(sm) {
    display: flex;
    display: -ms-grid;

    @supports (display: grid) {
      display: grid;
    }
  }

  &-TopicList {
    display: grid;
    -ms-grid-column-span: 21;
    -ms-grid-column: 1;
    grid-column: 1/21;
    -ms-grid-row: 1;
    grid-row: 1;
    grid-template-rows: min-content;

    @media #{$breakpoint-up-two-columns} {
      -ms-grid-column-span: 15;
      -ms-grid-column: 1;
      grid-column: 1/15;
      width: 75%;

      @supports (display: grid) {
        width: auto;
      }
    }

    @include media-breakpoint-up(mil) {
      -ms-grid-column-span: 20; // потому что иначе мало из-за width
      -ms-grid-column: 1;
      grid-column: 1/16;
    }
  }
}
