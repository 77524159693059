@import "src/styles/variables";

.ChatPopup {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 11;

  width: 300px;
  height: 400px;

  background-color: #fff;
  border: 1px solid #808080;
  border-radius: 5px;

  &-Header {
    margin: 0;
    padding: 10px 0.4rem;

    font-weight: bold;

    background-color: #FFF1BA;
    border-bottom: 1px solid #FFD119;
    border-radius: 5px 5px 0 0;
  }

  &-Title {
    margin-right: 2rem;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-CloseButton {
    position: absolute;
    top: 0;
    right: 0;

    padding: 0.75rem;

    border-top-right-radius: 5px;
    cursor: pointer;

    user-select: none;

    &:hover {
      color: $danger;
    }
  }

  &-List {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-x: visible;
    overflow-y: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &_mode {
      &_dialog {
        bottom: 39px; /* Чтобы убрать жирную рамку над полем ввода сообщения */
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-AddContactToggle {
    width: 100%;
    padding: 5px 0;

    text-align: center;
    color: #a0a0a0;

    border-bottom: 1px solid #808080;
    cursor: pointer;

    &:hover {
      color: $danger;
    }
  }

  &-AddContactForm {
    display: flex;
    gap: 0.4rem;
    padding: 0.5rem;

    color: #a0a0a0;

    border-bottom: 1px solid #808080;
    cursor: pointer;
  }

  &-AddContactInput {
    width: 100%;
  }

  &-ListItem {
    position: relative;

    width: 100%;
    padding: 0.4rem 0;

    border-bottom: 1px solid #808080;
    cursor: pointer;

    &_mode {
      &_dialog {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  &-ListItemName {
    margin-left: 0.4rem;
  }

  &-ListItemClose {
    position: absolute;
    top: 0;
    right: 0;

    padding: 0.4rem 0.75rem 0.4rem 0.4rem;

    color: #000;

    cursor: pointer;

    user-select: none;

    &:hover {
      color: $danger;
    }
  }

  &-Textarea {
    position: absolute;
    bottom: 0;
    left: 0;

    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    padding-top: 0.6rem;
    padding-right: 40px;

    border-top: 1px solid #ccc;
    border-radius: 0 0 5px 5px;

    resize: none;
    overflow-wrap: break-word;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-SendButton {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 40px;
    height: 40px;
    padding: 0.5rem;

    background: transparent;
    border: none;
    border-bottom-right-radius: 5px;
    cursor: pointer;

    &:hover {
      .ChatPopup-IconPath {
        fill: $danger;
      }
    }
  }

  &-IconPath {
    fill: $primary;
  }
}
