@import "src/styles/variables";

.Switcher_vertical {
  &-Label {
    box-sizing: border-box;
    width: 100%;
    margin-right: 0;

    &:not(:first-child) {
      border-top: none;
    }

    &:first-child {
      border-radius: $border-radius-input $border-radius-input 0 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius-input $border-radius-input;
    }

    &:first-child:last-child {
      border-top-left-radius: $border-radius-input;
      border-top-right-radius: $border-radius-input;
    }
  }
}
