@import "src/styles/variables";

.Title {
  margin: 0;

  &-Caption {
    display: inline-block;

    font-weight: normal;
    font-size: 0.75rem;
    vertical-align: top;
    color: $danger;
  }
}
