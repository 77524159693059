.PinnedTasks {
  &-List {
    margin-bottom: 2rem;
    padding-left: 0;

    list-style-type: none;
  }

  &-ListItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  &-ListItemButton {
    margin-left: 0.5rem;

    font-size: 1.25rem;
  }
}
