@import "src/styles/variables.scss";

.CloseButton {
  display: block;

  line-height: 1.25rem;

  &:hover {
    color: $danger;
  }
}
