@import "src/styles/variables";

.Link2_view_pseudo {
  border-bottom-width: $border-pseudo-width;
  border-bottom-style: dashed;

  &:hover {
    border-color: $border-dangerous;
  }
}
