/**
  * 1. Убираем нативную подсветку на iOS и Android.
  */

@import "src/styles/variables";

.Link_selected {
  color: $primary;

  border: none;
  cursor: default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    color: $black !important;
  }

  .Link-U {
    color: $primary;

    border: none;
  }
}
