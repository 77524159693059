@import "src/styles/variables";

.SearchIcon {
  width: 14px;

  &-Svg {
    enable-background: new 0 0 27.9 50;
  }

  &-Lens {
    fill: none;
    stroke: $primary;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }

  &-Handle {
    fill: $primary;
  }
}
