.SidebarBottomAd {
  &-Google {
    position: sticky;
    top: 0.4rem;
    left: 0;

    &_development {
      height: 750px;
    }
  }

  &-GoogleBanner {
    display: block;
  }
}
