@import "src/styles/variables", "src/styles/breakpoints";

.VariantLink {
  display: inline-block;
  margin: 0.25rem;
  padding: 0.25rem 0.25rem 0.35rem;

  text-align: center;

  border-width: $border-width;
  border-style: solid;
  border-radius: $border-radius-input;

  @supports (display: grid) {
    & {
      display: block;
      margin: 0;
      padding: 1rem 0 1.1rem;
    }

    @include media-breakpoint-up(md) {
      padding: 0.25rem 0 0.35rem;
    }
  }

  &_new {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -0.25rem;
      right: -0.25rem;

      width: 1rem;
      height: 1rem;

      background: $danger;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        top: -0.25rem;
        right: -0.35rem;

        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  &-Hint {
    width: 1.4rem;
    height: 1.4rem;
    margin: -0.1rem 0 -0.1rem 0.4rem !important;
    padding: 0 !important;
    padding-left: 0.05rem !important;

    border-radius: 50% !important;
  }
}
