.LiteratureWriting {
  padding-top: 0.75rem;

  text-align: center;

  &-Link {
    font-size: 1.5rem;
    color: #f00;

    border-color: rgba(255, 0, 0, 0.25);
  }
}
