@import "src/styles/variables";

.Fallback {
  background: $border-primary-light;
  border-color: transparent;
  cursor: wait;

  animation: 1s ease-in-out infinite alternate blink;

  user-select: none;
}

@keyframes blink {
  from {
    background: $border-primary-light;
  }

  to {
    background: adjust-color($primary, $alpha: -0.95);
  }
}
