.PinnedTasks {
  &-Row {
    margin-bottom: 0.75rem;
  }

  &-Label {
    display: flex;
  }

  &-LabelText {
    margin-left: 0.35rem;
  }

  &-Link {
    display: block;

    &:hover {
      color: #000 !important;
    }
  }
}
