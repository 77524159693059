@import "src/styles/breakpoints";

.MobileMenu {
  position: relative;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 50px;
  padding: 0 3rem 4px 10px;

  background: #f8fbfd;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  cursor: pointer;

  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::after {
    content: "";
    position: absolute;
    top: 20px;
    right: 24px;

    width: 16px;
    height: 10px;

    background: url('data:image/svg+xml,%3Csvg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M2 2L15 15.5L28 2" stroke="black" stroke-width="3"/%3E%3C/svg%3E%0A')
      0 / contain no-repeat;

    transition: transform 0.1s linear;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
