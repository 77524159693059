@import "src/styles/breakpoints", "src/styles/variables";

.ChangePasswordPopup {
  &-Control {
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  &-FormText {
    margin-bottom: 1rem;

    text-indent: 0;
  }

  &-Label {
    display: block;
    margin-bottom: 0.5rem;
  }

  &-FormRow {
    position: relative;

    display: block;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &-FormInput {
    width: 100%;
    padding-right: calc(#{$input-padding-side * 2} + 1.25rem);

    &_email {
      display: none;
    }

    @include media-breakpoint-up(md) {
      width: 15rem;
    }
  }
}
