.Button {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  letter-spacing: inherit;

  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;

  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;

  &:not(&:focus-visible) {
    outline: none;
  }

  &_disabled {
    cursor: wait;
  }
}
