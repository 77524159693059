@import "src/styles/variables", "src/styles/transitions";

.Input {
  box-sizing: border-box;
  margin: 0;
  padding: 0.3rem $input-padding-side 0.4rem;

  font-size: inherit;
  font-family: inherit;

  background: $input-bg;
  border: none;
  border-radius: $border-radius-input;
  outline: none;
  box-shadow: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include transition;

  &:hover:not(&:focus):not(&_focused) {
    background: $input-bg-hover;
  }

  &:focus,
  &_focused {
    background: $white;
    box-shadow: 0 1px 3px 1px $border-black;
    -webkit-appearance: none;
  }

  &:hover,
  &:focus,
  &_focused {
    @include transition-reset;
  }
}
