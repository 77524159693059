@import "src/styles/breakpoints", "src/styles/variables";

.RecoveryEmailPopup {
  &-Form {
    display: none;

    &_visible {
      display: block;
    }
  }

  &-FormText {
    margin-bottom: 1rem;

    text-indent: 0;
  }

  &-Label {
    display: block;
    margin-bottom: 0.5rem;
  }

  &-FormRow {
    display: block;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &-FormInput {
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &-Alert {
    margin: 0;

    color: $danger;

    &_visible {
      margin-top: 0.75em;

      @include media-breakpoint-up(md) {
        margin-top: 0.5em;
      }
    }
  }
}
