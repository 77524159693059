@import "src/styles/variables";

.AdBlockBlockMessage-Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.AdBlockBlockMessage-BlockDetect {
  padding: 1.5rem 2.5rem 1rem 1rem;
  max-width: 80vw;
  text-align: justify;
  color: $white;
  background: $danger;
  background: linear-gradient(
    to bottom right, #fb0, $danger
  );
  border-radius: $border-radius-button;
}
.AdBlockBlockMessage-BlockDetectTitle {
  margin: 0;
}
.AdBlockBlockMessage-BlockDetectText {
  line-height: 1.4rem;
}

