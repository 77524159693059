.ChatButton {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 1;

  width: 30px;
  height: 15px;
  padding: 4px 5px 6px;

  font-size: 10pt;
  text-align: center;

  background: #f0f0f0;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}
