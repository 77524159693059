/**
 *  1. Чтобы поле не стеснялось сжиматься.
 *     {@link https://redmine.sdamgia.ru/issues/178 Тикет}
 */
@import "src/styles/breakpoints.scss", "src/styles/variables.scss";

.Form {
  position: relative;

  &-Row {
    &_oneLine {
      display: flex;
    }
  }

  &-Input {
    &_oneLine {
      flex-grow: 1;
      width: 100%;
      min-width: 100px; /* 1 */
      margin-right: 0.25rem !important;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }

  &-Alert {
    position: absolute;
    top: 2.25rem;
    left: 0.55rem;

    color: #f60;

    &_view_action {
      top: 2.5rem;
      right: 0;
      left: 0;

      padding: 0.5rem 0.5rem 0.75rem;

      background-color: #fff;
      border: 1px solid $border-black;
      border-radius: $border-radius-input;

      @supports (backdrop-filter: saturate(180%) blur(20px)) {
        background-color: rgba(255, 255, 255, 0.5);
        border: none;

        backdrop-filter: saturate(180%) blur(20px);

        @include media-breakpoint-up(md) {
          backdrop-filter: none;
        }
      }

      @include media-breakpoint-up(md) {
        top: 2.25rem;
        left: 0.55rem;

        padding: 0;

        background-color: transparent;
        border: none;
      }
    }
  }
}
