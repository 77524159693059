@import "src/styles/variables", "src/styles/breakpoints";

.AlertListChat-Alert {
  &_view_stack {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      box-shadow: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 0.5rem;
      left: 0.5rem;
      z-index: -1;

      height: 10px;

      background: #fff1ba;
      border-radius: $border-radius-input $border-radius-input 0 0;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &_clickable {
    cursor: pointer;
  }

  &_type {
    &_success {
      background: $success;
      border-color: $border-success;
    }
  }

  &-NotifyBell {
    width: 2.5rem;
    height: 2.5rem;

    background: url("../assets/BellNotify.svg") 0 / cover no-repeat;
  }
  &-WrapBell {
    background: white;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 32px #rgb(0 0 0 / 8%);
    box-shadow: ("0 0 32px rgb(0 0 0 / 8%)");
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
  }
}
