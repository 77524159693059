.ProfileLink {
  display: flex;
  flex-wrap: wrap;

  &-Link {
    margin-right: 0.25rem;

    font-weight: bold;
  }

  &-Button {
    cursor: pointer;
  }

  &-Icon {
    &:hover {
      opacity: 0.75;
    }
  }
}
