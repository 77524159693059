@import "src/styles/breakpoints";

.Footer {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(mil) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-Menu {
    margin-bottom: 1rem;

    @include media-breakpoint-up(mil) {
      margin-bottom: 0.5rem;
    }
  }

  &-Copyright {
    margin-bottom: 2rem;

    @include media-breakpoint-up(mil) {
      margin-bottom: 1rem;
    }
  }

  &-TextItem {
    line-height: 2rem;

    @include media-breakpoint-up(mil) {
      line-height: 1.4rem;
    }
  }

  &-Partner {
    order: 3;

    @include media-breakpoint-up(mil) {
      & {
        order: initial;
      }
    }
  }
}
