@import "src/styles/variables";

.News {
  &-Thieves {
    &_hidden {
      display: none;
    }
  }

  &-Item {
    display: block;
    margin: 0 0 0.75rem 0.4rem;
    padding: 0 0.25rem 0.4rem 0;

    line-height: 1.05rem;

    &_hot {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: -0.5px;
        left: -5px;

        width: 2px;

        background: $danger;
        background: linear-gradient(to bottom right, #fb0, $danger);
      }
    }

    &_urgent {
      margin-left: 0;
      padding-top: 3px;
      padding-right: 0;

      text-align: center !important;

      background: $danger;
      background: linear-gradient(
        to bottom right, #fb0, $danger
      ) !important;
    }
  }

  &-Date {
    margin-bottom: -1px;

    font-size: 8px !important;

    opacity: 0.75;
  }

  &-Text {
    margin-top: 0;

    text-decoration: none;
  }

  &-Fallback {
    height: 2.5rem;
  }
}
