@import "src/styles/variables";

.Link2_selected {
  font-weight: bold;
  color: $primary;

  &:visited {
    color: $primary;
  }

  .Link2-U {
    border-bottom: none;
  }
}
