/*
 * 1. Усиление специфичности,
 *    потому что перебивали стили у Link_pseudo.
 */

@import "src/styles/variables", "src/styles/breakpoints";

.ProfileAuth {
  width: 100%;
  margin-bottom: 2rem;

  &-Title {
    &_platform {
      &_mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      &_desktop {
        display: none !important;

        @include media-breakpoint-up(md) {
          display: block !important;
          margin-top: 0 !important;
        }
      }
    }
  }

  &-Form {
    margin-bottom: 0.4rem;
  }
  &-CheckBox {
    background: #ddd;
    margin-right: 5px;
  }

  &-InputGroup {
    margin-bottom: 0.15rem;
  }

  /**
   * @todo (ilyasidorchik)
   * Сделать отдельный компонент Label
   */

  &-Label {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &-LabelText {
    margin-bottom: 0.25rem;

    user-select: none;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &-Input {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.75rem;

    font-size: 1rem;

    &::placeholder {
      color: transparent;

      @include media-breakpoint-up(md) {
        color: #999;

        opacity: 1;
      }
    }

    &_invalid {
      border-color: $danger;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0.4rem;
      padding: 2px;

      font-size: 9pt;

      background: #f4f8ff;
      border: 1px solid #5983ff;
      border-radius: $border-radius-input;
      box-shadow: inset 0 0 3px rgba(77, 77, 77, 0.35);
    }
  }

  &-Alert {
    color: $danger;

    &_visible {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: -0.05rem;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      line-height: 0.95rem;
    }
  }

  &-AlertContent {
    &:not(&:first-child) {
      padding-left: 0.3rem;

      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &-ButtonGroup {
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
  }

  &-Button {
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;

      font-size: 9pt;
    }
  }

  &-Links {
    display: flex;
    flex-direction: column;
  }

  &-Link {
    margin-bottom: 0.3rem !important; /* 1 */
    padding: 0.3rem 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 0.1rem !important; /* 1 */
      padding: 0.1rem 0;
    }

    &_selected {
      color: $black;

      border-bottom: none;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0.5rem;
  }
}
