@import "src/styles/variables", "src/styles/breakpoints", "src/styles/transitions";

.Counter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-Button {
    box-sizing: border-box;
    width: 1.95rem;
    height: 1.95rem;
    padding: 0 0 0.1rem;

    font-size: 1rem;
    font-family: Verdana, sans-serif;
    text-align: center;

    background: transparent;
    border-width: $border-width;
    border-style: solid;
    border-color: lighten($black, 90%);
    border-radius: 50%;
    outline: none;
    box-shadow: none;

    &:not(&_disabled):hover {
      color: $danger;

      border-color: $border-dangerous;
      cursor: pointer;

      @include transition-reset;
    }

    &_disabled {
      color: $light;
    }

    @include transition;
  }

  &-Input {
    position: relative;
    top: 0;
    z-index: 1;

    width: 3rem;

    text-align: center;
  }

  &-Caption {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;

      font-size: 1rem;
    }
  }

  @include media-breakpoint-up(sm) {
    align-items: flex-start;
  }
}
