@import "src/styles/breakpoints";

.ChangePasswordPopup-Fieldset {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  border: none;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
