@import "src/styles/variables", "src/styles/breakpoints";

.DeskCard {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 36px;
  margin-bottom: -1rem;
  padding: 0.4rem;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-button;

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 20px;

    margin: 0;
  }

  &:hover,
  &_open {
    color: $danger;

    border-color: $border-dangerous;

    .DeskCard-Icon {
      opacity: 0.75;
    }
  }

  &_favourite {
    padding-right: 1rem;

    @include media-breakpoint-up(md) {
      left: 25px;
    }
  }

  &_help {
    width: 36px;

    @include media-breakpoint-up(md) {
      right: 20px;
    }
  }
}
