@import "src/styles/variables", "src/styles/breakpoints";

$breakpoint-up-two-columns: "screen and"
  (
    min-width: 830px
  );

.ConstructorForm {
  &-Buttons {
    -ms-grid-column-span: 21;
    -ms-grid-column: 1;
    grid-column: 1/21;
    -ms-grid-row: 2;
    grid-row: 2;
    min-width: 200px;

    @include media-breakpoint-up(sm) {
      max-width: 200px;
      margin-left: 0.5rem;

      @supports (display: grid) {
        max-width: initial;
        margin-left: 0;
      }
    }

    @media #{$breakpoint-up-two-columns} {
      -ms-grid-column-span: 7;
      -ms-grid-column: 15;
      grid-column: 15/21;
      -ms-grid-row: 1;
      grid-row: 1;
    }

    @include media-breakpoint-up(mil) {
      -ms-grid-column-span: 6;
      -ms-grid-column: 16;
      grid-column: 16/21;
      -ms-grid-row: 1;
      grid-row: 1;
    }
  }

  &-ButtonsPanel {
    margin: 1rem -0.5rem 0;
    padding: 1rem 0.4rem 1.75rem;

    background: $info;

    @media #{$breakpoint-up-two-columns} {
      position: -webkit-sticky;
      position: sticky;
      top: 0.5rem;

      margin: 28px 0 0.95rem;
    }
  }

  &-SubmitButton {
    height: 4.25rem;
    margin-bottom: 0.9rem;

    white-space: normal;
  }

  &-Switcher {
    margin-bottom: 0.75rem;
  }

  &-ResetButton {
    padding: 0 1rem 0.35rem;
  }

  &-ResetButtonText {
    margin-left: 0.45rem;

    font-size: 1rem;
  }
}
.CTSubmitButton {
  width: 100%;
}
