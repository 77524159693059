@import "src/styles/variables", "src/styles/breakpoints";

.SidebarInfo {
  position: relative;

  padding: 7.5px 0 1rem;

  font-size: 11px;
  text-align: left;

  background: $info;
  border-radius: $border-radius-button;

  &-Title {
    display: block;
    margin: 1rem 0 0.2rem;
    padding-bottom: 0.2rem;

    font-size: 11px;
    text-align: center;
    text-transform: uppercase;

    &_view_link {
      font-weight: bold;
    }
  }

  &-Item {
    padding: 0 0.4rem;
  }

  &-ProfileWidget {
    display: none;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
