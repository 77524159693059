.Message {
  cursor: initial !important;

  &-Date {
    position: absolute;
    top: 0;
    right: 0.4rem;

    font-size: 8px;
    color: #808080;
  }

  &-Person {
    margin: 0.4rem;

    font-weight: bold;
  }

  &-Text {
    margin: 0.4rem;

    white-space: pre-line;
  }
}
