@import 'src/styles/variables', 'src/styles/transitions';

.Switcher_checkbox {
	&-Label {
		display: flex;
		padding: 0.4rem;

		border-color: $border-primary;

		&:hover {
			background: adjust-color($primary, $alpha: -0.9) !important;

			.Switcher_checkbox-FakeCheckbox {
				background: adjust-color($primary, $alpha: -0.9) !important;

				@include transition-reset;
			}
		}
	}

	&-FakeCheckbox {
		border: $border-width solid $border-primary;

		@include transition;
	}

	&-Text {
		padding: 0 0 0 0.6rem;

		line-height: 1.25rem;

		background: transparent !important;
	}
}
