@import "src/styles/breakpoints";

.Html {
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}

.Page {
  margin: 0;
  overflow-y: scroll;

  font-family: Verdana, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background: #fff url("./assets/wallpaper.svg") repeat-x;
}

@media (min-width: 1500px) {
  body {
    background-size:100% !important;
  }
}

.Root {
  margin-top: -3px;
  padding-top: 3px;

  
}

.Halfspace {
  font-size: 0.5em;
  line-height: 1;
}

.Smallcapitals {
  margin-right: -0.1em;

  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.Nowrap {
  white-space: nowrap;
}

.Shaking {
  position: relative;
}

.TextCropped {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}


@media (prefers-color-scheme: dark) {
  body {
      background-color: #111 !important;
  }
  body .Root, .ChatPopup, .ChatButton, .AlertList, .Invertable>div, .ui-dialog {
      filter: #{"invert() hue-rotate(180deg)"};
  }
  body .Root img, .ChatPopup img, .ChatButton img, .AlertList img, .Invertable>div img, .ui-dialog img, .Header-Logo {
      filter: #{"invert() hue-rotate(180deg)"};
  }
  body .Root .pbody img, body .Root .solution img {
      filter: none;
  }
  body[data-theme=auto] .no_dark {
    filter: #{"invert() hue-rotate(180deg)"};
  }
}

body[data-theme=dark] {
  background-color: #111 !important;
}
body[data-theme=dark] .Root, 
body[data-theme=dark] .ChatPopup, 
body[data-theme=dark] .ChatButton, 
body[data-theme=dark] .AlertList, 
body[data-theme=dark] .Invertable>div,
body[data-theme=dark] .ui-dialog
{
  filter: #{"invert() hue-rotate(180deg)"};
}
body[data-theme=dark] .Root img,
body[data-theme=dark] .ChatPopup img,
body[data-theme=dark] .ChatButton img,
body[data-theme=dark] .AlertList img,
body[data-theme=dark] .Invertable>div img,
body[data-theme=dark] .ui-dialog img,
body[data-theme=dark] .no_dark,
 {
  filter: #{"invert() hue-rotate(180deg)"};
}

body[data-theme=dark] .Root .pbody img, body[data-theme=dark] .Root  .solution img {
  filter: none;
}

body[data-theme=light] {
  background-color: #fff !important;
}
body[data-theme=light] .Root,
body[data-theme=light] .ChatPopup,
body[data-theme=light] .ChatButton,
body[data-theme=light] .AlertList,
body[data-theme=light] .Invertable>div,
body[data-theme=light] .ui-dialog, 
body[data-theme=light] .Header-Logo
{
  filter: none;
}
body[data-theme=light] .Root img,
body[data-theme=light] .ChatPopup img,
 body[data-theme=light] .ChatButton img, 
 body[data-theme=light] .AlertList img,
 body[data-theme=light] .Invertable>div img,
 body[data-theme=light] .ui-dialog img
 {
  filter: none;
}
body[data-theme=light] .pbody img, body[data-theme=light] .solution img {
  filter: none;
}

/* part of style.css */

.answers {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
}
.answers>label{
margin: 0 15px;
}
.pbody img,
.solution img {
max-width: 100%;
}
.answer_option {
display: inline-block;
min-width: 130px;
margin: 0 10px;
}
.pbody p,
.solution p {
margin: 0;
text-indent: 0;
}
.probtext {
clear:both;
}
.left_margin,
.pbody .left_margin,
.solution .left_margin,
.probtext .left_margin {
margin: 0;
text-indent: 25px;
}
.probtext td {
  text-align: justify;
}
.pbody table,
.solution table,
.probtext table {
text-indent: 0;
}
.pbody th,
.pbody td,
.probtext th,
.probtext td,
.solution td,
.solution th {
padding: 4px;
border-collapse: collapse;
font-size: 10pt !important;
border: 1px solid #000;
}
.pbody th,
.solution th,
.probtext th {
font-size: 9pt !important;
color: #000;
background: #dedede;
}
.pbody table,
.solution table,
.probtext table {
border-collapse: collapse;
}
.pbody .no_border th,
.pbody .no_border td,
.probtext .no_border td {
padding: 4px;
border-collapse: collapse;
border: 0;
}
.prob_maindiv .prob_crits {
  width: 100%;
}
.tex {
margin-top: 3px;
}
.nobreak {
clear: both;
color: #000;
page-break-inside: avoid;
}
.pbody p[align=center], .solution [align=center], .pbody center, .solution center, div[id^=comments] center{margin-top:10px;margin-bottom:10px;}

div.flex-wrapper {
display: flex;
flex-wrap: wrap;
}
div.col2 {
flex: 1 0 50%;
white-space: nowrap;
text-align: left;
padding: 5px 0px;
box-sizing: border-box;
text-indent: 0;
}

div.col3 {
flex: 1 0 33.3%;
white-space: nowrap;
text-align: left;
padding: 5px 0px;
box-sizing: border-box;
text-indent: 0;
}
div.col4 {
flex: 1 0 25%;
white-space: nowrap;
text-align: left;
padding: 5px 0px;
box-sizing: border-box;
text-indent: 0;
}
div.col5 {
flex: 1 0 20%;
white-space: nowrap;
text-align: left;
padding: 5px 0px;
box-sizing: border-box;
text-indent: 0;
}
div.col6 {
flex: 1 0 16.6%;
white-space: nowrap;
text-align: left;
padding: 5px 0px;
box-sizing: border-box;
text-indent: 0;
}

.flex-wrapper-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 5px;
  margin-top: 5px;
}
.flex-wrapper-items p {
  text-indent: 0 !important;
  padding: 0 7px;
}
.flex-wrapper-items p:last-child:empty {
  display: none;
}
.pbody table,
.solution table,
.probtext table {
position:relative;
}
.flex-img {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-img > div {
  margin:10px;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  text-indent: 0;
  text-align: center;
}
.flex-img p:empty {
  display:none;
}
.tab_scale {
  border-collapse: collapse;
  border-color: orange;
  box-shadow: 0 2px 8px rgba(18, 17, 36, 0.04), 0 4px 32px rgba(18, 17, 36, 0.08);
  margin: unset;
  border-radius:0;
}

.tab_scale th {
  color: #000;
  background: #ff66004f;
  padding: 7px;
  border: none;
  border-color: orange;
}
.tab_scale td {
  width: 23px;
  padding: 3px;
  text-align: center;
}
.tab_scale_b td {
  width: 55px;
}
.wrap_tab_scale {
  max-width:80%;
  width:fit-content;
  margin:auto;
}

.wrap_flex_scale {
  display: -webkit-flex; 
  display: flex; 
  max-width: 90%;
  width: fit-content;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.tab_flex_scale_col_main {
  font-weight: 700;
  width: 128px;
  margin: 0px;
  margin-bottom: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 40px rgba(18, 17, 36, 0.04), 0 4px 32px rgba(18, 17, 36, 0.08);
}
.tab_flex_scale_col_main .tab_flex_scale_item {
  padding: 4px;
  text-align: center;
  margin: 0px;
}

.tab_flex_scale_col {
  margin: 0px;
  margin-bottom: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 40px rgba(18, 17, 36, 0.04), 0 4px 32px rgba(18, 17, 36, 0.08);
}
.tab_flex_scale_col .tab_flex_scale_item {
  width: 24px;
  padding: 4px;
  text-align: center;
  margin: 0px;
}

.tab_flex_scale_item:nth-child(odd) {
  background: #f7b99087;
}
.tab_flex_scale_item:nth-child(even) {
  background: #f7b99030;
}

/* Стили для таблицы с двумя столбиками -- начало */
.wrap_flex_table {    
  display: -webkit-flex;
  display: flex;
  max-width: 80%;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
} 
@media screen and (max-width: 750px) {
  .wrap_flex_table {
      max-width: 100%;
 }
}

.wrap_flex_table .col_name { 
  text-align: center;
  font-size: 10pt;
  text-indent: 0;
  margin-bottom: 5px;
} 
.wrap_flex_table_col {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 4%;
  margin-left: 4%;
} 
.wrap_flex_table_col .col_content {
  font-size: 10pt;
  margin: auto;
  width: fit-content;
} 
.wrap_flex_table_col .col_content p, .wrap_flex_table .col_name p {
  text-indent: 0;
}
.wrap_flex_table_col .p_center {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wrap_flex_table_col .col_content p {
  font-size: 10pt;
  /*display: flex;
  flex-wrap: nowrap;*/
}
.wrap_flex_table_col .col_content p img {
  max-width: 78vw;
}
.wrap_flex_table_col .not_flex p {
  display: block !important;
}
.wrap_flex_table_col .p_center p {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col_content p:last-child:empty {
  display: none;
}
/* Стили для таблицы с двумя столбиками -- конец */

/* Стили для одного столбика с выравниванием по левому краю -- начало */
.wrap_flex_center {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
} 
.wrap_flex_left {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.wrap_flex_center .left_margin, .wrap_flex_left .left_margin {
  text-indent:0 !important;
}
/* Стили для одного столбика с выравниванием по левому краю -- конец */

.flex-math {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
  flex-flow: wrap;
}
.flex-math p {
  /*max-width: 70%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  text-indent: 0 !important;
}
.flex-math img, .flex-math .left_margin {
  margin-left: 35px;
  margin-right: 35px;
}
.flex-math .left_margin img {
  margin-left: 0px;
  margin-right: 0px;
}
.flex-math p:last-child:empty {
  display: none;
}

/* Стили для центровки формулы только на телефоне -- начало */
@media screen and (max-width: 750px){
  .wrap-center-mobile {
      display: block;
      margin: auto;
      width: fit-content;
      text-indent: 0;
      margin-top: 10px;
      margin-bottom: 10px;
  }
}
/* Стили для центровки формулы только на телефоне -- конец */


/* ответы зно и мнт */
.zno-table {
  background: silver;
  width: fit-content;
  padding: 5px;
  font-weight: bold;
  border-radius: 3px;
}
.zno-table input[type=radio] {
  position: absolute;
  opacity: 0;
  display: block;
}
.zno-table label, .zno-table .label {
  width:24px;
  height:24px;
  display: inline-block;
  position: relative;
  line-height: 24px;
  text-align: center;
  vertical-align: bottom;
  box-sizing: border-box;
  margin: 1px;
}
.zno-table label {
  border:1px solid black;
  background: white;
}
.zno-table input[type=radio]:checked+span::after{
  content: "";
  position: absolute;
  height: 2px;
  width: 22px;
  left: 11px;
  top: 11px;
  background: #000;
  transform: translate(-50%,-50%) rotate(45deg);
}
.zno-table input[type=radio]:checked+span::before{
  content: "";
  position: absolute;
  height: 2px;
  width: 22px;
  left: 11px;
  top: 11px;
  background: #000;
  transform: translate(-50%,-50%) rotate(-45deg);
}

.wrap_scroll_table {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}
.wrap_scroll_table table {
  margin: auto;
}
@media screen and (min-width: 750px) {
  .wrap_scroll_table table, .solution table {
      max-width: 80%;
 }
}
@media screen and (max-width: 750px){
  .hide640 {
      display: none !important;
  }
  .small640 {
      left: 0 !important;
      bottom:5px !important;
      padding: 2px !important;
      width: auto !important;
      height: auto !important;
      font-size: 6pt !important;
  }
}
.wrap_img_caption {
  width: fit-content;
  margin: auto;
}
.caption_img_map {
  display:block;
  text-align:right;
  margin-top:-5px;
  margin-bottom:15px;
  font-size: 8pt;
}

.prob_nums {
  display: inline-flex;
  align-items: center;
}
.alert_dot_under_answer {
  display: block;
  text-align: left;
  color: red;
  margin-top: 5px;
}
.jq-ui-dialog-max-width {
  max-width: 95vw;
}

.rus_dashed, .rus_dotteddash, .rus_double, .rus_wave, .rus_single {
  background-repeat: repeat-x;
  background-position: bottom left;
  padding:3px 0;
  line-height: 20px;
}

.rus_dashed {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAADCAYAAACnI+4yAAAABmJLR0QA/wB/AABsilOeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIGEyAJdwM8sQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAZSURBVAjXY2AgETAyVDL8x6uinYGRgRIAAK31AgLE7/LwAAAAAElFTkSuQmCC");
}
.rus_dotteddash {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAADCAYAAACasY9UAAAABmJLR0QA/wB/AABsilOeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIGEx4lBB5LLwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAkSURBVAjXY2CgEDAyVDL8Z2BgYGBoZ2BkqISL/yeor52BOgAAwcAEAibtGoIAAAAASUVORK5CYII=");
}
.rus_double {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAYAAABS3WWCAAAABmJLR0QA/wB/AABsilOeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIGEisDdeBmUwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAASSURBVAjXY2CoZPjPAAGVDP8BE5MC8bD4IBsAAAAASUVORK5CYII=");
}
.rus_single {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAYAAABS3WWCAAAABmJLR0QA/wB/AABsilOeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIGEiovXiM78QAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAWSURBVAjXBcEBAQAAAIIgbru+AJCBAwnRAXm0COegAAAAAElFTkSuQmCC");
}
.rus_wave {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAADCAYAAACuyE5IAAAABmJLR0QA/wB/AABsilOeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIGEjAGrLxZRgAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAiSURBVAjXY2RABpUM/+HsdgZGBpySDAyMCH4lw380SRRNAH9BCVvoCTITAAAAAElFTkSuQmCC");
}


.rus_term {/*термин, определение*/
font-style: italic;
color: #800000;
font-weight:bold;
font-size: 11pt;
}

.rus_subterm { /*подтермин*/
  font-style:italic; 
  color: #800000; font-weight:bold;
}

.rus_rule {/*правило*/
font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, sans-serif;
font-size: 10pt;
color: #800000;
}

.rus_exclusion { /*исключение*/
font-family: Constantia, "Lucida Bright", "DejaVu Serif", Georgia, serif;
font-style:italic; 
font-size: 11pt;
color: #FF0000
}

.rus_example { /*пример*/
font-style: italic;
}

.rus_notes { /*примечание*/
  font-style:italic;	
color: #800000; }
.sup_cont {position:relative; display:inline-block; padding-top:13px; text-indent:0;line-height: 15px;}
.sup_word {white-space:nowrap; position:absolute; top:0; left:0; right:0; text-align:center; font-size:0.8em}

span.prefix, span.root, span.suffix, span.ending {
  line-height: 28px;
}
