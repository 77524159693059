.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background: #fff;

  // @media (prefers-reduced-motion: no-preference) {
  //   &-Logo {
  //     animation: Spinner-LogoAnimation infinite 10s linear;
  //   }
  // }
  &-Panel {
    display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7.5rem;
  }

  &-Logo {
    display: none;
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 1.25rem;

    pointer-events: none;
  }

  &-Text {
    display: none;
  }
}

// @keyframes Spinner-LogoAnimation {
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(-360deg);
//   }
// }
