@import "src/styles/variables", "src/styles/transitions";

@mixin Link-Style {
  font-style: inherit;
  font-weight: inherit;
  color: $primary;
  text-decoration: none;

  border-color: $border-primary;
  border-bottom-width: $border-width;
  border-bottom-style: solid;

  @include transition;
}

.Link {
  @include Link-Style;

  &:not(&_static):not(&_black):not(&_white):not(&_pseudo):visited,
  &-U:not(&_static-U):not(&_black-U):not(&_white-U):not(&_pseudo-U):visited {
    color: $visited;

    border-color: $border-visited !important;
  }

  &:not(&:focus-visible) {
    outline: none;
  }

  &:hover {
    color: $danger !important;

    border-color: $border-danger !important;

    @include transition-reset;

    // Для ХТМЛ новости из админки
    font[color="red"] {
      color: $danger;
    }
  }

  &-U {
    @include Link-Style;
  }
}
