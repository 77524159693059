@import "src/styles/breakpoints", "src/styles/variables";

.ContentAd {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center; /* Для ins Гугла */
  max-width: calc(100vw - 20px);

  @include media-breakpoint-up(sm) {
    max-width: 100%;
  }

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }

  /** Иначе реклама не уменьшается
   *  и появляется горизонтальный скролл
   */
  @media screen and (min-width: 918px) {
    max-width: 100%;
  }

  &-Item {
    &_development {
      background: $ad;
    }
  }
}
