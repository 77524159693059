@import "src/styles/variables", "src/styles/breakpoints";

.header-title {
  margin-left: 10px;
  display: flex;
  max-width: 62vw;
  justify-content: left;
  gap: 7px;
  flex-wrap: wrap;
  align-items: center;
}
.header-title a {
  text-decoration: none;
}
.headerTitleUA {
  font-family: Verdana,Geneva,sans-serif;
  font-size: 27px;
  line-height: 27px;
  padding-top: 5px;
  letter-spacing: 1.5px;
  background: linear-gradient(90deg, #f99117, #ffd645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
span.headerTitleUA_active {
  font-weight: bold;
}
span.headerTitleUA_noactive{
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-decoration: none;
  cursor: pointer;

}
.headerTitleUA_noactiveWrap{
  padding: 5px 10px;
}
.headerTitleUAWrap_active, .headerTitleUA_noactiveWrap {
  display: inline-block;
}
.headerTitleUAWrap_active, .headerTitleUA_noactiveWrap:hover{
  border-radius: 8px;
  --box-shadow-color: rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 0 32px var(--box-shadow-color);
  box-shadow: 0 0 32px var(--box-shadow-color);
  background: white;
  padding: 5px 10px;
}
@include media-breakpoint-up(md) {
  .header-title {
    margin-left: 0px;
    margin-bottom: 10px;
    gap: 10px;
    max-width: none;
  }
}
.headerTitleUA h1 {
  margin: 0;
  font-size: inherit;
  display: inherit;
  margin-block: 0px;
}

@media (max-width: 645px) {
.headerTitleUA {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0px;
}
.headerTitleUA_noactiveWrap:hover {
  padding: 5px 10px;
}
}
