@import "src/styles/breakpoints";

.DeskList {
  position: sticky;
  /* top: 35px; when user hid an ad */
  top: 25px;
  z-index: 4;

  display: flex;
  justify-content: space-between;

  &_fixedOnTop {
    position: relative;
    top: 0;

    @include media-breakpoint-up(md) {
      position: inherit;
    }
  }
}
