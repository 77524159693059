/**
 *  1. Высота нужна, чтобы вылечить баг в Chrome 49.0.2623.112 на Windows XP,
 *     из-за которого съезжали вниз предметы под предметом с выпадайкой.
 *     Такое магическое значение, чтобы было ок
 *     в Хроме, Сафари и при этом в Мозиле ¯\_(ツ)_/¯
 *     Подробнее: https://redmine.sdamgia.ru/attachments/26
 */

@import "src/styles/variables", "src/styles/breakpoints";

$subjectMenuItemRadius: 14px;

.SubjectMenu {
  &_open {
    &::after {
      transform: rotate(180deg);
    }
  }

  &_desktop {
    display: none;
    

    &-Link {
      min-width: auto;
      max-width: none;

      color: $black;
      text-decoration: none;
      white-space: nowrap;
      @include media-breakpoint-up(md) {
        max-width: 143px;
      }
    }

    &-Tab {
      float: left;
      width: calc(50% - 22px);
      min-width: 120px;
      max-width: none;
      height: 20px;
      margin: 1px 1px 1px 0;
      padding: 6px 10px 7px;

      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $black;

      background: transparent;
      border-radius: 14px;
      box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px rgba(255, 102, 0, 0.5);
      cursor: pointer;
      backdrop-filter: blur(3px);

      &:hover {
        color: #f60;
      }

      &_selected {
        font-weight: bold;
      }

      @include media-breakpoint-up(md) {
        width: 100px;
        height: initial;

        font-size: 11px;
        line-height: initial;
      }

      @include media-breakpoint-up(mil) {
        width: calc(14.28% - 21px);
      }
    }

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &_fallback {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex !important;
      flex-wrap: wrap;
      margin-bottom: -2px !important;
    }
  }

  &_show {
    display: block !important;
    margin-top: 0 !important;
  }

  &-Tab {
    overflow: hidden;

    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
      overflow: initial;
    }

    &_disabled {
      color: #333 !important;

      background: #f0f0f0;
    }
  }

  &-Link {
    &_fallback {
      box-sizing: border-box;
      width: 140px;
      height: 25px;
      margin: 1px 1px 3px;
      padding: 1px;

      border-radius: $subjectMenuItemRadius;
    }

    &_disabled {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline;

        pointer-events: none;
      }
    }
  }

  &-PopupBlock {
    position: relative;

    //height: 27px; /* 1 */
    overflow: visible;

    &:hover {
      @media (hover: hover) {
        .SubjectMenu-Popup {
          display: block;
          z-index: 9;

          background: #f8fbfd;
          border-radius: $subjectMenuItemRadius;
        }
      }
    }

    @include media-breakpoint-up(md) {
      float: left;
    }
  }

  &-PopupTitle {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline;

      line-height: initial;
    }
  }

  &-PopupLink {
    &_selected {
      font-weight: bold;
    }

    &_mobile {
      display: inline;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
  }

  &-Popup {
    @include media-breakpoint-up(md) {
      position: absolute;
      z-index: 9;

      display: none;
      float: left;
      width: 142px;
    }
  }

  &-PopupTab {
    width: calc(50% - 22px);

    @include media-breakpoint-up(md) {
      width: calc(100% - 21px);
    }
  }
}

.SubjectMenu-Ruler_clear {
  &_right {
    clear: right;
  }

  &_both {
    clear: both;
  }
}

.SubjectMenu-Checkbox {
  display: none;
}

@media (hover: none) {
  input:checked~.SubjectMenu-Popup {
    display: block;

    background: #f8fbfd;
    border-radius: $subjectMenuItemRadius;
  }
}
