@import "src/styles/variables";

.SidebarSearch {
  font-size: 9pt;

  &-Label {
    position: relative;

    display: block;

    cursor: text;
  }

  &-Icon {
    position: absolute;
    top: 0;
    left: 5.5px;
  }

  &-Input {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 6px 2px 7px 26px;

    font-size: inherit;

    border-radius: $border-radius-button;
    outline: none;

    &_loading {
      background: $input-bg !important;
      box-shadow: none !important;
      cursor: wait;
    }
  }

  &-Alert {
    margin: 0.25rem 0 0.75rem 26px;

    color: $danger;
  }
}
