@import "src/styles/breakpoints", "src/styles/variables";

.ChangePasswordPopup {
  &-Form {
    display: none;

    &_visible {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }

  &-ButtonPart {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 1.7rem;
    }
  }
}
