@import "src/styles/breakpoints", "src/styles/variables";

.ContentTopAd {
  margin-bottom: 0.5rem;
}

.ContentTopAd_fixedHeight {
  min-height: 25vh;

  @include media-breakpoint-up(xs) {
    min-height: 25vh;
  }

  @include media-breakpoint-up(sm) {
    min-height: 25vh;
  }

  @include media-breakpoint-up(md) {
    min-height: 185px;
  }
}

.ContentTopAd_fixedHeightCT {
  min-height: 13vh;

  @include media-breakpoint-up(xs) {
    min-height: 13vh;
  }

  @include media-breakpoint-up(sm) {
    min-height: 13vh;
  }

  @include media-breakpoint-up(md) {
    min-height: 100px;
  }
}
