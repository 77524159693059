@import "src/styles/variables", "src/styles/breakpoints";

.LarinVariants {
  &-TitleWord {
    &_mobile {
      @media (min-width: 660px) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @media (min-width: 660px) {
        display: inline;
      }
    }

    &_order {
      &_1 {
        display: block;

        @media (min-width: 420px) {
          display: inline;
        }
      }

      &_3 {
        display: inline;

        @media (min-width: 420px) {
          display: none;
        }

        @media (min-width: 500px) {
          display: inline;
        }
      }
    }
  }

  &-Variants {
    -ms-grid-columns: 5% 5% 5% 5% 5% 5% 5% 5%
      5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%;
    grid-template-columns: repeat(6, 1fr);
    margin-top: -0.5rem;
    padding-top: 0.5rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(10, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(20, 1fr);
    }

    &_cut {
      height: 13.25rem;
      overflow: hidden;

      border-bottom: 0.05rem solid $border-primary;
      box-shadow: inset 0 -5px 10px -10px $primary;

      @include media-breakpoint-up(md) {
        height: 7.9rem;
      }
    }
  }

  &-Link {
    &:nth-child(20n+1) {
      -ms-grid-column: 1;
    }

    &:nth-child(20n+2) {
      -ms-grid-column: 2;
    }

    &:nth-child(20n+3) {
      -ms-grid-column: 3;
    }

    &:nth-child(20n+4) {
      -ms-grid-column: 4;
    }

    &:nth-child(20n+5) {
      -ms-grid-column: 5;
    }

    &:nth-child(20n+6) {
      -ms-grid-column: 6;
    }

    &:nth-child(20n+7) {
      -ms-grid-column: 7;
    }

    &:nth-child(20n+8) {
      -ms-grid-column: 8;
    }

    &:nth-child(20n+9) {
      -ms-grid-column: 9;
    }

    &:nth-child(20n+10) {
      -ms-grid-column: 10;
    }

    &:nth-child(20n+11) {
      -ms-grid-column: 11;
    }

    &:nth-child(20n+12) {
      -ms-grid-column: 12;
    }

    &:nth-child(20n+13) {
      -ms-grid-column: 13;
    }

    &:nth-child(20n+14) {
      -ms-grid-column: 14;
    }

    &:nth-child(20n+15) {
      -ms-grid-column: 15;
    }

    &:nth-child(20n+16) {
      -ms-grid-column: 16;
    }

    &:nth-child(20n+17) {
      -ms-grid-column: 17;
    }

    &:nth-child(20n+18) {
      -ms-grid-column: 18;
    }

    &:nth-child(20n+19) {
      -ms-grid-column: 19;
    }

    &:nth-child(20n) {
      -ms-grid-column: 20;
    }

    &:nth-child(n+21) {
      -ms-grid-row: 2;
    }

    &:nth-child(n+41) {
      -ms-grid-row: 3;
    }

    &:nth-child(n+61) {
      -ms-grid-row: 4;
    }

    &:nth-child(n+81) {
      -ms-grid-row: 5;
    }

    &:nth-child(n+101) {
      -ms-grid-row: 6;
    }

    &:nth-child(n+121) {
      -ms-grid-row: 7;
    }

    &:nth-child(n+141) {
      -ms-grid-row: 8;
    }

    &:nth-child(n+161) {
      -ms-grid-row: 9;
    }

    &:nth-child(n+181) {
      -ms-grid-row: 10;
    }

    &:nth-child(n+201) {
      -ms-grid-row: 11;
    }

    &:nth-child(n+221) {
      -ms-grid-row: 12;
    }

    &:nth-child(n+241) {
      -ms-grid-row: 13;
    }

    &:nth-child(n+261) {
      -ms-grid-row: 14;
    }

    &:nth-child(n+281) {
      -ms-grid-row: 15;
    }

    &:nth-child(n+301) {
      -ms-grid-row: 16;
    }

    &:nth-child(n+321) {
      -ms-grid-row: 17;
    }

    &:nth-child(n+341) {
      -ms-grid-row: 18;
    }

    &:nth-child(n+361) {
      -ms-grid-row: 19;
    }

    &:nth-child(n+381) {
      -ms-grid-row: 20;
    }

    &:nth-child(n+401) {
      -ms-grid-row: 21;
    }

    &:nth-child(n+421) {
      -ms-grid-row: 22;
    }

    &:nth-child(n+441) {
      -ms-grid-row: 23;
    }

    &:nth-child(n+461) {
      -ms-grid-row: 24;
    }

    &:nth-child(n+481) {
      -ms-grid-row: 25;
    }

    &:nth-child(n+501) {
      -ms-grid-row: 26;
    }

    &:first-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: -3px;
        right: -4px;

        width: 0.725rem;
        height: 0.725rem;

        background: #f60;
        border-radius: 50%;
      }
    }
  }

  &-Control {
    margin-bottom: -0.8525rem;
    padding-bottom: 0.8525rem;
  }
}
