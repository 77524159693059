@import "src/styles/variables", "src/styles/breakpoints";

.ConstructorForm {
  &-Row {
    display: -ms-grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 1fr;
    grid-column-gap: 0.75rem;
    margin-bottom: 1.5rem;

    @supports (display: grid) {
      display: grid;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      display: -ms-grid;
      -ms-grid-columns: 20% 80%;
      grid-template-columns: 135px 1fr;
      margin-bottom: 0.75rem;

      @supports (display: grid) {
        display: grid;
        grid-template-columns: 135px 1fr;
      }
    }

    @include media-breakpoint-up(md) {
      display: grid;
      -ms-grid-columns: 16% 86%;
      grid-template-columns: 105px 1fr;
      margin-bottom: 0.75rem;
    }

    &_head {
      display: none;
      padding-bottom: 0.75rem;

      font-weight: bold;
      color: $primary;

      border-bottom: 0.05rem solid $border-primary;

      @include media-breakpoint-up(sm) {
        display: flex;
        display: -ms-grid;

        @supports (display: grid) {
          display: grid;
          grid-column-gap: 1.1rem;
        }
      }
    }

    &_withoutNumber {
      grid-column-gap: 0.75rem;
    }

    &_label {
      -ms-grid-columns: 0 100%;
      grid-template-columns: 1fr;
      margin-top: 2rem;

      font-style: italic;
      color: $primary;

      @include media-breakpoint-up(sm) {
        margin-top: 1rem;
      }

      &:first-child {
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
          margin-top: 0.25rem;
        }
      }
    }

    &_hidden {
      display: none;
    }

    &_fallback {
      height: 1.75rem;

      border-radius: 0.25rem;
    }

    &_toggler {
      grid-template-columns: none;
    }
  }

  &-Counter {
    -ms-grid-row: 2;
    grid-row: 2;
    width: 135px;
    width: 169px;
    margin: 0.2rem 0 0 -0.2rem;

    @include media-breakpoint-up(sm) {
      -ms-grid-row: auto;
      grid-row: auto;
      width: 105px;
      margin: 0;

      @supports (display: grid) {
        width: auto;
      }
    }
  }

  &-Topic {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;

    &_withTitle {
      &_default {
        margin-left: 0.75rem;
      }

      &_enlarged {
        margin-left: 1.5rem;
      }
    }

    &_withoutNumber {
      margin-left: 0;
    }

    @include media-breakpoint-up(sm) {
      -ms-grid-row: auto;
      grid-row: auto;
    }
  }

  &-TopicDesc {
    -ms-grid-column: 2;
    margin-left: 0.25rem;
    &-GroupTitle {
      grid-column: 1/3;
    }
    @supports (display: grid) {
      margin-left: 0;
    }
  }

  &-TopicName {
    position: relative;

    display: flex;
    display: -ms-grid;
    -ms-grid-columns: auto 1fr;
    padding: 0.15rem 0 0.25rem;

    font-size: 1rem;
    line-height: 1.5rem;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 0.35rem;
    }

    @include media-breakpoint-up(md) {
      margin-left: 1rem;

      @supports (display: grid) {
        margin-left: 0;
      }
    }

    @include media-breakpoint-up(md) {
      -ms-grid-columns: 2rem 1fr;
      grid-template-columns: 2.5rem 1fr;
      box-sizing: border-box;
      padding-right: 1.5rem;
    }

    &_view {
      &_numberless {
        display: block;
      }
    }

    &_type {
      &_extra {
        @include media-breakpoint-up(md) {
          -ms-grid-columns: 4.25rem 1fr;
          grid-template-columns: 4.25rem 1fr;
        }
      }

      &_enlarged {
        @include media-breakpoint-up(md) {
          -ms-grid-columns: 2.75rem 1fr;
          grid-template-columns: 2.75rem 1fr;
        }
      }
    }

    &_platform {
      &_webkit {
        padding: 0.3rem 0 0.4rem;
      }
    }

    &.CheckAll {
      @include media-breakpoint-up(sm) {
        position: relative;
        top: 0.15rem;
        left: -2rem;

        height: 0;
        padding: 0;
      }
    }

    &_tooglerItem {
      grid-template-columns: none;
    }
  }

  &-TopicNumber {
    text-align: right;
  }

  &-TopicSubCheckbox {
    margin-top: 0.15rem;
  }

  &-TopicSubs {
    display: none;

    &_open {
      display: block;
    }
  }
}
