/*
 * 1. Временное решение.
 *    Ставит текущий попап выше шторки входа.
 */

@import "src/styles/breakpoints", "src/styles/variables";

.RecoveryEmailPopup {
  z-index: 12; /* 1 */

  &_platform {
    &_touch {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inherit;
      }
    }
  }

  &-DrawerContent {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &-Content {
    @include media-breakpoint-up(md) {
      max-width: 510px;
    }
  }

  &-Title {
    line-height: $line-height-mobile;

    @include media-breakpoint-up(md) {
      line-height: $line-height-desktop;
    }
  }
}
