@import "src/styles/variables", "src/styles/breakpoints";

/**
 * 1. При значении < 1 верхний баннер Яндекс-директа
 *    перекрывает модальное окно,
 *    при < 5 не затеняется кнопка для отложенных заданий.
 * 2. В мобильном сафари есть неприятный bug,
 *    связанный с -webkit-overflow-scrolling: touch.
 *    Дело в том, что при применении этого свойства,
 *    вложенный контент может просто пропадать, это решается
 *    включением аппаратного рендеринга, что и сделано.
 *    Подробнее: http://cantina.co/thought_leadership/
 *    ios-5-native-scrolling-grins-and-gothcas/
 */

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5; /* 1 */

  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &_theme {
    &_normal {
      .Modal-Content {
        background-color: $white;
      }
    }
  }

  &_loading {
    cursor: wait;
    opacity: 0;
  }

  &-Table {
    display: table;
    width: 100%;
    height: 100%;
  }

  &-Cell {
    display: table-cell;

    text-align: center;

    &_align {
      &_middle {
        vertical-align: middle;
      }
    }
  }

  &-Content {
    position: relative;
    z-index: 1;

    display: inline-block;

    text-align: left;

    border: $border-width solid $light;
    transform: translate3d(0, 0, 0); /* 2 */

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-button;
    }
  }

  &-Header {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;

    border-bottom: $border-width solid $light;
  }

  &-HeaderButton {
    padding: 0 1rem 0.25rem;

    font-size: 2.5rem;
    color: $primary;
  }

  &-Title {
    margin: 1rem 0 1.25rem;
  }

  &-Main {
    padding: 1rem 1rem 2.5rem;
  }

  &-Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(50, 50, 50, 0.2);
    touch-action: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
