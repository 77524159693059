@import "src/styles/breakpoints";

.SidebarMenu {
  &_fallback {
    margin-bottom: -4px;
  }

  &-Link {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    margin: 0 0 1rem;

    vertical-align: top;

    @include media-breakpoint-up(md) {
      display: block;
      box-sizing: initial;
      width: 100%;
      height: 18px;
      margin-top: 3px;
      margin-bottom: 0;
      padding-top: 6px;
      padding-left: 5px;

      font-size: 9pt;
    }

    &_important {
      color: #f00;
    }

    &_sdamgia {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &-Fallback {
    margin: 0.4rem 0;

    border-radius: 5px;
  }

  &-LinkUnderline {
    &_important {
      border-bottom-color: rgba(255, 0, 0, 0.25);
    }
  }
}
