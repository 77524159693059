@import "src/styles/variables";

.Link2 {
  &_wrap {
    border-bottom: none;
  }

  &-U {
    text-decoration: none;
  }

  &-U_wrap {
    color: inherit;

    border-bottom-width: $border-pseudo-width;
    border-bottom-style: dashed;
  }
}
