@import "src/styles/variables", "src/styles/breakpoints";

.Sidebar {
  box-sizing: border-box;
  width: 100%;
  min-width: 160px;
  padding: 0 0.4rem;

  color: $black;

  @include media-breakpoint-up(md) {
    box-sizing: initial;
    width: 160px;

    border-right: 2px solid #c0c0c0;
  }

  @include media-breakpoint-up(mil) {
    padding-left: 0;
  }

  &_shown {
    display: block !important;
  }

  &-MobileMenu {
    margin-top: -1px;

    &_open {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &-Menu {
    margin: -0.25rem 0 1.5rem;
  }

  &-Search {
    margin: 14px 0 7px;
  }

  &-Info {
    margin-bottom: 1.25rem;
  }
}
