@import "src/styles/breakpoints.scss", "src/styles/variables.scss";

.TeacherVariant {
  &-Alert {
    display: flex;
    align-items: center;

    padding: 16px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: #{"0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%)"};

    @include media-breakpoint-up(md) {
      align-items: flex-end;
    }
  }

  &-AlertText {
    margin: 0;

    &_mobile {
      display: inline;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
  }

  &-AlertButton {
    margin: 0.25rem 0 0 0.5rem;
  }
}
