@import "src/styles/breakpoints";

.AlertList {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;

  @include media-breakpoint-up(md) {
    right: 10px;
    bottom: 10px;
    left: auto;
  }
}
