@import "src/styles/variables";

.SpecialChars {
  &-DescCard {
    margin-top: 3rem;
    padding-left: 1rem;
  }

  &-ContentCard {
    position: fixed;
    top: 7.5rem;
    left: 25px;

    padding: 0.4rem;

    text-indent: 0;

    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-button;
  }
}
