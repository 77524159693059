@import "src/styles/breakpoints";

.ContentBottomAd-Yandex {
  max-width: 350px;
  min-height: 90px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: 100%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}
