@import "src/styles/breakpoints";

.Drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11; // Иначе перекрывает реклама и уведомление о входе

  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    display: none !important;
  }

  &_visible {
    display: block;
  }

  &-DragObserver {
    display: flex;
    height: inherit;
  }

  &-Curtain {
    position: relative;

    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-height: calc(100% - 32px);

    will-change: transform;
  }

  &-Content {
    position: relative;

    overflow: auto;

    -webkit-overflow-scrolling: touch;
  }

  &-InnerContent {
    padding: 20px;
  }

  &-Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    will-change: opacity;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
