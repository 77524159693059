@import "src/styles/variables";

.ContentTopAd-BlockDetect {
  padding: 1.5rem 2.5rem 1rem 1rem;
  color: $white;
  background: $danger;
  background: linear-gradient(
    to bottom right, #fb0, $danger
  );
  border-radius: $border-radius-button;
}
.ContentTopAd-BlockDetectTitle {
  margin: 0;
}
.ContentTopAd-BlockDetectText {
  line-height: 1.4rem;
}

