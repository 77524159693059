.Bots {
  &_hidden {
    display: none;
  }

  &-List {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  &-Item {
    width: 50px;
    height: 50px;

    &:hover {
      opacity: 0.75;
    }

    &:first-child {
      margin-right: 0.4rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
