/**
 * 1. Значение индекса 1 спасает от перекрытия меню предметов,
 *    значение 3 — от перекрытия рекламой,
 *    значение 5 — от перекрытия «липкой» кнопкой на отложенные задания
 */

@import "src/styles/variables", "src/styles/breakpoints",
  "src/styles/transitions";

$header-background: #f8fbfd;

.Header {
  &-ExamSwitcher {
    position: absolute;
    left: -7px;
    z-index: 5; /* 1 */

    margin: 0;
    padding: 0;

    list-style: none;

    background: $header-background;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);

    animation-name: popup_view_default_bottom;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    &_olymp {
      min-width: 11rem;
    }

    &_visible {
      animation-name: popup_view_default_bottom_visible;
    }

    @include media-breakpoint-up(md) {
      top: 2.25rem;
      &_olymp {
        right: 0.5rem;
      }
    }
  }

  &-ExamSwitcherItem {
    background: linear-gradient(90deg, #f99117, #fcb630);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      opacity: $hover-opacity;

      @include transition-reset;
    }

    &:first-child {
      .Header-ExamSwitcherLink {
        margin-top: 0;
      }
    }

    &:last-child {
      .Header-ExamSwitcherLink {
        padding-bottom: 0.5rem;
      }
    }

    @include transition;

    @include media-breakpoint-up(md) {
      background: none;
      background-clip: initial;
      -webkit-text-fill-color: initial;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-ExamSwitcherLink {
    display: block;
    width: auto !important; // Иначе ссылка зачем-то кликабельна справа
    margin: 0.25rem 0;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  }

  &-ExamSwitcherUnderline {
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      border-bottom-color: #ffd645 !important;
    }
  }
}

@keyframes popup_view_default_bottom {
  0% {
    margin: 0;

    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  99% {
    margin: 0;

    transform: translateY(10px);
  }

  100% {
    margin: -9999px 0 0 -9999px;

    visibility: hidden;
    opacity: 0;
  }
}

@keyframes popup_view_default_bottom_visible {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
