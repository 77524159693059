$white: #fff;
$black: #000;
$primary: #006;
$info: #f0f5ff;
$visited: #8f00af;
$danger: #f60;
$light: #ccc;
$success: #008000;
$ad: #ff6;

$input-bg: #f0f0f0;
$input-bg-hover: adjust-color($input-bg, $lightness: -5%);
$success-bg: #c0ffc0;

$border-width: 0.05rem;
$border-pseudo-width: 0.1rem;

$border-primary: adjust-color($primary, $alpha: -0.75);
$border-primary-light: adjust-color($primary, $alpha: -0.9);
$border-visited: adjust-color($visited, $alpha: -0.75);
$border-black: adjust-color($black, $alpha: -0.75);
$border-white: adjust-color($white, $alpha: -0.5);
$border-success: #87f087; // лучше наследоваться от $success
$border-danger: adjust-color($danger, $alpha: -0.75);
$border-dangerous: adjust-color($danger, $alpha: -0.5);

$border-radius-button: 0.5rem;
$border-radius-input: 0.25rem;

$hover-opacity: 0.6;

// Типографика
$line-height-mobile: 1.6rem;
$line-height-desktop: 1.4rem;

// Отступы
$input-padding-side: 0.55rem;
