@import "src/styles/variables", "src/styles/breakpoints";

.Alert {
  position: relative;

  padding: 10px 10px 25px;

  line-height: 25px;

  background-color: rgb(255, 241, 186);

  &-HideAll {
    padding-bottom: 10px;
    border-top: 1px solid grey;
    text-align: center;
    border-left: 1px solid grey;
    &-Full {
      border-left: none;
    }
  }
  &-HideAllMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &-Full {
      grid-template-columns: 1fr;
    }
  }

  @include media-breakpoint-up(md) {
    width: 400px;
    margin-bottom: 2px;
    padding: 5px;

    line-height: normal;

    border: 1px solid rgb(255, 209, 25);
    border-radius: 5px;
    &-HideAllMain {
      //max-width: 400px;
      //display: ;
      display: flex;
      justify-content: space-between;
      width: 412px;
    }
    &-HideAll {
      padding-bottom: 5px;
      width: 193px;
      &-Full {
        width: 400px;
      }
    }
  }

  &:not(:last-child) {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &-HideAll:not(:last-child) {
    display: block;
  }

  &-Header {
    display: flex;
    justify-content: space-between;
  }

  &-Date {
    position: absolute;
    top: 8px;
    right: 30px;

    display: none;

    font-size: 10px;
    color: #808080;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &-CloseButton {
    z-index: 1;

    margin: -10px -10px -50px -50px;
    padding: 10px 17.5px 50px 50px;

    font-weight: bold;
    font-size: 18px;

    cursor: pointer;

    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    touch-action: manipulation;

    @include media-breakpoint-up(md) {
      margin: -5px -16px -5px -15px;
      padding: 5px 16px 5px 20px;

      font-size: 12px;

      &:hover {
        color: #f60;
      }
    }
  }

  &-Title {
    margin-top: 0;

    font-weight: bold;
    font-size: 18px;
    color: #000;

    &_mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }

  &-Text {
    font-size: 18px;

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }

    &_mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &-Hyphen {
    @media screen and (min-width: 321px) {
      display: none;
    }
  }
}
