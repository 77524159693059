@import "src/styles/variables";

.Link_white {
  color: $white;

  &:hover {
    color: $white !important;

    border-bottom-color: $white;
  }

  &-U {
    color: $white;

    border-bottom-color: $border-white;
  }
}
