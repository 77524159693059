@import "src/styles/variables", "src/styles/breakpoints";

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &-Main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include media-breakpoint-up(md) {
      border-bottom: none;
      align-items: initial;
      user-select: auto;
      -webkit-tap-highlight-color: auto;
    }
  }

  &-LogoLink {
    width: 50px;
    height: 50px;

    border: none;

    @include media-breakpoint-up(md) {
      width: 100px;
      height: 100px;
      margin: 0 33px;
    }
  }

  &-Logo {
    width: 50px;
    height: 50px;

    @include media-breakpoint-up(md) {
      width: 100px;
      height: 100px;
    }
  }

  &-Info {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &-Title {
    position: relative;

    margin: 0px 0 0 5px;

    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0;

    background: linear-gradient(90deg, #f99117, #fcb630);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(xs) {
      margin-left: 10px;
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 10px;

      font-weight: normal;
      letter-spacing: 2.5px;

      background: none;
      background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  &-SystemName {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline;
    }
  }

  &-Link {
    font-weight: bold;

    border-bottom-color: #ffd645 !important;

    &:hover {
      border-bottom-color: #f99117 !important;
    }

    @include media-breakpoint-up(md) {
      font-weight: normal;

      border-bottom-color: $border-black !important;

      &:hover {
        border-bottom-color: $border-danger !important;
      }
    }
  }

  &-Halfspace {
    font-size: 0.5em;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &-WebsiteDesc {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;

      font-size: 14px;
      line-height: 20px;
    }
  }

  &-SubjectTitle {
    display: none;

    font-size: 14px;
    line-height: 16px;

    &_fallback {
      border-radius: 0.25rem;
    }

    @include media-breakpoint-up(md) {
      display: block;
      margin-bottom: 5px;

      font-size: 20px;
      line-height: 20px;
    }
  }

  &-SubjectImage {
    display: none;
    width: 346.667px;
    height: 100px;

    &_fallback {
      width: 75px;
      height: 75px;

      border-radius: 50%;
    }

    @include media-breakpoint-up(mil) {
      display: block;
    }
  }
  &-IsMobile {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
