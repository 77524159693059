@import "src/styles/variables";

.Button_view_default {
  padding: 0.25rem 1.1rem 0.5rem;

  color: #000;

  background: #d2e2ff;
  background: linear-gradient(to bottom, #d2e2ff, #9dccff);
  border-radius: $border-radius-button;
  box-shadow: inset -1px -1px 2px rgba(77, 77, 77, 0.3);

  transition-duration: 0.1s;

  &:hover {
    background: #dbe8ff;
    background: linear-gradient(to bottom, #dbe8ff, #b1d6ff);
  }

  &:active {
    background: #bfd6ff;
    background: linear-gradient(to bottom, #bfd6ff, #d2e2ff);
    box-shadow: inset 1px 1px 2px rgba(77, 77, 77, 0.4);
  }
}

.Button_disabled {
  background: #ccc;
  background: linear-gradient(to bottom, #ebebee, #ccc) !important;
  box-shadow: inset 1px 1px 2px rgba(77, 77, 77, 0.4);
}
