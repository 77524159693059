@import "src/styles/breakpoints", "src/styles/variables";

.Text {
  margin: 0;

  line-height: $line-height-mobile;
  text-align: justify;
  text-indent: 1.5rem;
  word-wrap: break-word;
  hyphens: auto;

  @include media-breakpoint-up(md) {
    line-height: $line-height-desktop;
  }
  &-red {
    color: red;
  }
}
