@import "src/styles/variables", "src/styles/breakpoints";

.Section {
  margin-bottom: 1.75rem;

  font-size: 1rem;

  border-top: 0.15rem solid $primary;

  &-ComplexHeader {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-Desc {
    margin-bottom: 1rem !important;
  }

  &-TwoColumns-Desktop {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
