@import 'src/styles/variables', 'src/styles/transitions';

.Switcher {
	font-size: 0;

	&-Label {
		display: inline-block;

		font-size: 1rem;
		color: $primary;

		background: transparent;
		border: $border-width solid $primary;

		@include transition;

		&:not(:first-child):not(.Switcher_vertical-Label) {
			border-left: none;
		}

		&:first-child {
			border-radius: $border-radius-input 0 0 $border-radius-input;
		}

		&:last-child {
			border-radius: 0 $border-radius-input $border-radius-input 0;
		}

		&:hover {
			@include transition-reset;

			.Switcher-Text {
				background: adjust-color($primary, $alpha: -0.9);

				@include transition-reset;
			}
		}
	}

	&-Text {
		display: block;
		padding: 4px 15px 6px;

		cursor: pointer;
	}

	&-Input {
		display: none;

		&:checked + .Switcher-Text {
			color: $white;

			background: $primary !important;
			cursor: default;
		}
	}
}
