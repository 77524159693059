@import "src/styles/variables", "src/styles/breakpoints";

.TwoColumns {
  &-Grid {
    -ms-grid-columns: 1fr;

    @include media-breakpoint-up(md) {
      display: flex;
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;

      @supports (display: grid) {
        display: grid;
      }
    }

    @supports (display: grid) {
      grid-row-gap: 0;
    }
  }

  &-Col {
    -ms-grid-column-span: 21;
    -ms-grid-column: 1;
    grid-column: 1/21;

    @include media-breakpoint-up(md) {
      -ms-grid-column-span: inherit;
      grid-column: span 10;
    }

    &_1 {
      /* Don't pay attention to this scss lint warning */
      -ms-grid-column: 1;
      margin-bottom: 1.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding: 0 0.4rem 1.5rem 0;

        border-right: 0.15rem solid $primary;
      }
    }

    &_2 {
      /* Don't pay attention to this scss lint warning */
      -ms-grid-column: 2;

      border-top: 0.15rem solid $primary;

      @include media-breakpoint-up(md) {
        padding-left: 0.5rem;

        border-top: none;

        @supports (display: grid) {
          padding-left: 0;
        }
      }
    }
  }
}
