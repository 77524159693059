/**
 * 1. @todo (ilyasidorchik)
 *    Попробовать наследоваться от Text
 */

@import "src/styles/breakpoints", "src/styles/variables";

.Title_level_4 {
  line-height: $line-height-mobile; /* 1 */

  @include media-breakpoint-up(md) {
    line-height: $line-height-desktop;
  }
}
