@import "src/styles/breakpoints";

.OurVariants {
  &-PersonalBox {
    margin-top: 0.4rem;

    text-align: center;
  }

  &-PersonalLink {
    display: block;

    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 0.25rem 1.1rem 0.35rem;
    }
  }

  &-HintPopupContent {
    max-width: 768px;
  }
}
