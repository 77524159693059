@import "src/styles/variables", "src/styles/breakpoints";

@mixin Link2Hover {
  color: $danger;

  border-color: $border-danger;
}

.Link2 {
  color: $primary;
  text-decoration: none;

  cursor: pointer;

  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: $visited;

    border-color: $border-visited;
  }

  &:active {
    @include Link2Hover;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      @include Link2Hover;
    }
  }

  &:not(&:focus-visible) {
    outline: none;
  }
}
