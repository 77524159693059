/**
 *  1. 12-колоночная сетка, потому что Д. Д. захотел
 *     6 колонок на телефоне, на с центровкой последних 3-х
 */

@import "src/styles/breakpoints";

.OurVariants {
  text-align: center;

  @supports (display: grid) {
    text-align: initial;
  }

  &-TitleWord1 {
    display: block;

    @include media-breakpoint-up(sm) {
      display: inline;
    }
  }

  &-TitleWord2 {
    display: inline-block;

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  &-Word {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline;
    }
  }

  &-Grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 20% 20% 20% 20% 20%; /* 1 */
    grid-template-columns: repeat(12, 1fr); /* 1 */
    grid-gap: 0.4rem;

    @include media-breakpoint-up(md) {
      -ms-grid-columns: 20% 20% 20% 20% 20%;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &-Link {
    grid-column: span 2;

    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15) {
      grid-row: 3;
    }

    &:nth-child(13) {
      grid-column: 4/6;
    }

    &:nth-child(14) {
      grid-column: 6/8;
    }

    &:nth-child(15) {
      grid-column: 8/10;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      -ms-grid-row: 1;
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      -ms-grid-row: 2;
    }

    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15) {
      -ms-grid-row: 3;
    }

    &:nth-child(1),
    &:nth-child(6),
    &:nth-child(11) {
      -ms-grid-column: 1;
    }

    &:nth-child(2),
    &:nth-child(7),
    &:nth-child(12) {
      -ms-grid-column: 2;
    }

    &:nth-child(3),
    &:nth-child(8),
    &:nth-child(13) {
      -ms-grid-column: 3;
    }

    &:nth-child(4),
    &:nth-child(9),
    &:nth-child(14) {
      -ms-grid-column: 4;
    }

    &:nth-child(5),
    &:nth-child(10),
    &:nth-child(15) {
      -ms-grid-column: 5;
    }

    @include media-breakpoint-up(md) {
      -ms-grid-column: auto !important; // иначе специфичнее nth-child
      grid-column: unset !important; // иначе специфичнее nth-child
      -ms-grid-row: auto !important;
      grid-row: unset !important;
    }
  }
}
