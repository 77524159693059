@import "src/styles/breakpoints";

.PageLayout {
  max-width: 1000px;
  margin: auto;
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);

  &-Header {
    @include media-breakpoint-up(md) {
      margin: 5px 0 0.75rem;
    }
  }

  &-Nav {
    @include media-breakpoint-up(md) {
      width: fit-content;
        margin: auto;
    }
  }

  &-Grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      display: flex;
      display: -ms-grid;
      -ms-grid-columns: 175px auto;
      margin-top: 0.4rem;

      @supports (display: grid) {
        & {
          display: grid;
          grid-template-columns: 175px 1fr;
        }
      }
    }

    @include media-breakpoint-up(mil) {
      -ms-grid-columns: 170px auto;
      grid-template-columns: 170px 1fr;
    }
  }

  &-Sidebar {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &-Content {
    /* Don't pay attention to this scss lint warning */
    -ms-grid-column: 2;
    margin: 0 0.5rem;

    @include media-breakpoint-up(md) {
      @supports (display: grid) {
        margin-left: 0;
      }
    }

    @include media-breakpoint-up(mil) {
      margin-right: 0;
    }
  }

  &-Main {
    margin-bottom: 5rem;
  }

  &-BannerAd {
    width: 100%;

    &_top {
      @include media-breakpoint-up(mil) {
        width: 823px !important;
        height: 185.683px;
      }
    }
  }

  &-Loading {
    text-align: center;
  }
  &-IsMobile {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      z-index: 100;
      position: relative;
    }
  }
}
button:active, button:focus, button:focus-visible, span:focus-visible, a:focus-visible {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}