@import "src/styles/breakpoints";

.ChangePasswordPopup-FormAlert {
  margin: 0;

  @include media-breakpoint-up(md) {
    margin: 0.5em 0 -1rem;
  }

  &:before {
    content: "\00a0";
  }
}
