/**
 * Базовые визуальные стили
 */

.Drawer_view_default {
  .Drawer-Content,
  .Drawer-Title {
    background: #fff;
  }

  .Drawer-Overlay {
    background: rgba(0, 0, 0, 0.4);
  }
}

/**
 * Стили для direction_bottom
 */

.Drawer_direction_bottom {
  .Drawer-DragObserver {
    flex-direction: column;
    justify-content: flex-end;
  }

  &.Drawer_view_default {
    .Drawer-Curtain {
      max-height: calc(100% - 32px);
    }

    .Drawer-Content,
    .Drawer-Title {
      border-radius: 16px 16px 0 0;
    }

    .Drawer-Handle {
      position: absolute;
      top: -19px;
      left: 50%;

      width: 40px;
      margin-left: -36px;
      padding: 8px 16px;

      will-change: opacity;

      &::before {
        content: "";

        display: block;
        height: 3px;

        background: #fff;
        border-radius: 100px;
      }
    }

    &.Drawer_nested {
      .Drawer-Handle {
        top: 0;
        z-index: 1;

        &::before {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
