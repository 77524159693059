@import "src/styles/breakpoints";

.ChangePasswordPopup {
  &_platform {
    &_touch {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: inherit;
      }
    }
  }

  &-DrawerContent {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
