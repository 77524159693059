@import "src/styles/variables.scss";

/**
 * @todo (ilyasidorchik)
 * Вынести общее в тему
 */

.Button_view_secondary {
  padding: 0.25rem 1.1rem 0.5rem;

  background: $white;
  border: 1px solid $border-black;
  border-radius: $border-radius-button;
  box-shadow: inset -1px -1px 2px rgba(77, 77, 77, 0.3);

  &:active {
    box-shadow: inset 1px 1px 2px rgba(77, 77, 77, 0.4);
  }

  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.03);
  }
}

.Button_disabled {
  background: rgba(0, 0, 0, 0.03);
}
