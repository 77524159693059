@import "src/styles/variables", "src/styles/transitions";

.ProfileIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -14px -10px -16px;
  padding: 10px;

  @include transition;

  &:active,
  &_selected {
    opacity: $hover-opacity;

    @include transition-reset;
  }

  &-Content {
    width: 2.5rem;
    height: 2.5rem;

    background: $danger;
    background: url("./assets/ProfileIcon.svg") 0 / cover no-repeat;
  }

  &-Caption {
    font-size: 0.65rem; // Так на Айфоне не шире рамки иконки
    // Чтобы был одинаковый шрифт. Да, inherit не помог
    font-family: Verdana, Arial, sans-serif;
    color: #f80; // Как у иконки
  }
}
