@import "src/styles/breakpoints", "src/styles/variables";

.ContentBottomAd {
  @include media-breakpoint-up(xl) {
    max-width: 823px;
  }

  &-Item {
    &_development {
      background: $ad;
    }
  }
}
