@import "src/styles/variables", "src/styles/transitions";

.Label {
  cursor: pointer;

  &:hover {
    .FakeCheckbox {
      background: $input-bg-hover;

      @include transition-reset;
    }
  }

  .FakeCheckbox {
    @include transition;
  }
}

.Checkbox {
  position: absolute;

  opacity: 0;

  pointer-events: none;
}

.FakeCheckbox {
  position: relative;

  display: inline-block;
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;

  &::after {
    content: "";
    position: absolute;
    top: -0.15rem;
    left: 0.2rem;

    display: block;
    width: 1.25rem;
    height: 1.25rem;

    background: url("./assets/tick.svg") center / 90% no-repeat;
    opacity: 0;
  }

  &_blue::after {
    background-image: url("./assets/tick_blue.svg");
  }

  .Checkbox:checked + &::after {
    opacity: 1;
  }

  .Checkbox[data-disabled="true"] + &::after {
    opacity: 0.3;
  }
}
